@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(5px);
  padding: 20px;

  @include bp(mobile) {
    padding: 40px;
  }

  @include bp(tablet) {
    padding: 80px;
  }

  @include bp(desktop) {
    padding: 80px 140px;
  }
}

.modalClose {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}

.modal {
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;

  iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  @include bp(desktop) {
    max-width: 1000px;
  }
}

.modal__body {
  position: relative;
  padding: 0;
  width: 100%;

  &Image {
    text-align: center;
  }
}

.btnClose {
  position: absolute;
  top: -45px;
  right: 0;
  border: none;
  // font-size: 16px;
  padding: 7px 12px;
  cursor: pointer;
  font-weight: 300;
  background: #fff;
  color: #000;
  background: transparent;
  z-index: 199;

  // top: 16px;
  // right: 16px;
  background: url("/images/close-icon__black.svg");
  width: 48px;
  height: 48px;
}

.modal__pdf {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}