@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.list {
  color: $color__black--lighter;
  background-color: #ffffff;
  padding: 56px 0 64px;

  h3 {
    font-size: 32px;
    line-height: 1.75;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;

    @include bp(tablet) {
      font-size: 44px;
    }

    @include bp(desktop-large) {
      font-size: 48px;
    }

    @include bp(desktop-wide) {
      font-size: 52px;
    }

    a {
      color: inherit;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    @include bp(tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;
      row-gap: 40px;
    }

    @include bp(desktop-large) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  li + li {
    margin-top: 24px;

    @include bp(tablet) {
      margin-top: 0;
    }
  }

  @include bp(tablet) {
    padding: 64px 0 88px;
  }

  @include bp(desktop-large) {
    padding: 80px 0 96px;
  }

  @include bp(desktop-wide) {
    padding: 64px 0 104px;
  }

  .list__header {
    margin-bottom: 24px;

    @include bp(tablet) {
      display: flex;
      align-items: baseline;
      margin-bottom: 32px;
    }

    @include bp(desktop-large) {
      margin-bottom: 40px;
    }

    @include bp(desktop-wide) {
      margin-bottom: 48px;
    }
  }

  .list__more {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: $color__green--light;

    @include bp(tablet) {
      font-size: 18px;
      margin-left: 16px;
    }

    @include bp(desktop-large) {
      display: inline;
      font-size: 20px;
    }
  }

  &.list_withpagination {
    padding: 56px 0 96px;

    @include bp(tablet) {
      padding: 64px 0 128px;
    }

    @include bp(desktop-large) {
      padding: 80px 0 136px;
    }

    @include bp(desktop-wide) {
      padding: 64px 0 144px;
    }
  }
}
