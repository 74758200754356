@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
@import "../../styles/pagination";

.pagination_readingroom.pagination {
  display: none;

  &_active {
    display: flex;
    align-items: center;
  }
}
