@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.intro {
  color: #ffffff;
  position: relative;
  padding: 48px 0 60px;
  overflow: hidden;
  min-height: calc(100vh - 64px);
  display: flex;
  align-items: center;

  @include bp(0 tablet) {
    background: url("/images/intro-main-bg-mobile.jpg");
    background-size: cover;
  }

  @include bp(tablet) {
    min-height: calc(100vh - 72px);
  }

  h1 {
    font-size: 26px;
    line-height: 1.23;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 16px;

    br {
      display: none;
    }

    @include bp(0 tablet) {
      br {
        display: block;
      }
    }
    @include bp(mobile tablet) {
      font-size: 56px;
      line-height: 84px;
    }
    @include bp(desktop-large) {
      max-width: 750px;
      margin-bottom: 28px;
    }
    @include bp(desktop-wide) {
      max-width: 816px;
    }
  }

  p {
    margin-bottom: 30px;
    text-align: center;
    @include bp(tablet) {
      text-align: left;

      br {
        display: none;
      }
    }
    @include bp(mobile tablet) {
      font-size: 32px;
      line-height: 48px;
    }
    @include bp(desktop-large) {
      max-width: 672px;
    }
    @include bp(desktop-wide) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &:before {
    @include bp(tablet) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(
        69.63deg,
        rgba(0, 0, 0, 0.56) 36.46%,
        rgba(3, 50, 71, 0) 87.6%
      );
    }
  }

  @include bp(desktop) {
    padding: 68px 0 80px;
  }

  @include bp(desktop-wide) {
    padding: 108px 0 120px;
  }

  &_event {
    min-height: auto !important;
    padding-top: 30px;
    padding-bottom: 208px;
    background-position: bottom;

    h1 {
      @include bp(tablet) {
        font-size: 40px;
        max-width: 552px;
      }
      @include bp(desktop-large) {
        font-size: 44px;
        line-height: 1.09;
      }
      @include bp(desktop-wide) {
        font-size: 48px;
        line-height: 1.16;
        max-width: 752px;
      }
    }

    @include bp(tablet) {
      height: 512px;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include bp(desktop-wide) {
      padding: 108px 0 60px;
    }
  }

  &_event:before {
    @include bp(tablet) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        50.93deg,
        rgba(0, 29, 41, 0.68) 27.6%,
        rgba(3, 50, 71, 0) 70.29%
      );
    }
  }

  .intro__place {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding-left: 28px;
    position: relative;
    text-align: left;
    margin-bottom: 16px;

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 11px;
      height: 16px;
      background: url("/images/pin-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    @include bp(desktop-large) {
      margin-bottom: 24px;
    }
  }
}

.intro__image {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: bottom;
  // display: none;
  transform: translateX(-50%);

  &_mobile {
    @include bp(tablet) {
      display: none;
    }
  }

  &_desktop {
    display: none;
    @include bp(tablet) {
      display: block;
      object-position: right;
    }
  }

  &_onlydesktop {
    display: block !important;
  }
}

.intro__container {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 3;
}

.intro__logo {
  width: 188px;
  height: 56px;
  margin: auto;
  margin-bottom: 36px;
  display: block;

  @include bp(mobile tablet) {
    width: 288px;
    height: 86px;
  }

  @include bp(tablet) {
    margin-bottom: 120px;
  }
}

.intro__btn {
  // @include bp(0 tablet) {
  //   display: block;
  //   margin: auto;
  // }
}

.intro__time {
  display: flex;
  align-items: center;
  color: $color__blue;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 16px;

  span {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 3px 12px 2px;
    background-color: $color__blue;
    margin-right: 16px;

    @include bp(0 tablet) {
      display: none;
    }
    @include bp(desktop-large) {
      font-size: 26px;
    }
    @include bp(desktop-wide) {
      font-size: 28px;
      padding-top: 1px;
      padding-bottom: 1px;
      max-height: 40px;
    }
  }

  sub {
    font-size: 10px;
    font-weight: 500;
    margin-top: 10px;

    @include bp(tablet) {
      font-size: 12px;
    }
  }

  @include bp(tablet) {
    font-size: 24px;
  }

  @include bp(desktop-large) {
    font-size: 26px;
  }

  @include bp(desktop-wide) {
    font-size: 28px;
  }
}
