@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.menu {
  color: #ffffff;
  position: relative;
  strong {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    color: #ffffff;
    @include bp(mobile) {
      font-size: 40px;
      line-height: 64px;
    }
  }
  @include bp(tablet) {
    display: none;
  }
}

.menu__top {
  background-color: $color__green--light;

  img {
    @include bp(mobile) {
      width: 48px;
      height: 64px;
    }
  }
}

.menu__topcontainer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;

  @include bp(mobile) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.menu__items {
  background-color: #22a96e;
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  z-index: 3;
  @include bp(mobile) {
    top: 128px;
  }
}

.menu__item {
  display: block;
  padding: 10px;

  &_current {
    display: none;
  }
}
