@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.list {
  padding-bottom: 72px;

  h1 {
    font-size: 32px;

    @include bp(tablet) {
      font-size: 44px;
    }

    @include bp(desktop-large) {
      font-size: 48px;
      margin-bottom: 40px;
    }

    @include bp(desktop-wide) {
      font-size: 52px;
      margin-bottom: 48px;
    }
  }

  @include bp(tablet) {
    padding-bottom: 88px;
  }

  @include bp(desktop-large) {
    padding-bottom: 96px;
  }

  @include bp(desktop-wide) {
    padding-bottom: 104px;
  }
}

.list__breadcrumbs {
  a,
  span {
    color: $color__darkblue-2;
  }
}

.list__items {
  list-style: none;
  margin: 0;
  padding: 0;

  @include bp(tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }

  @include bp(desktop-large) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
  }

  @include bp(desktop-wide) {
    column-gap: 40px;
  }
}

.list__item {
  margin-bottom: 32px;

  @include bp(tablet) {
    margin-bottom: 0;
  }
}
