@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.videos {
  color: $color__black--lighter;
  background: #f7f8f9;
  padding-top: 60px;
  padding-bottom: 60px;

  h3 {
    font-size: 32px;
    line-height: 1.75;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;

    @include bp(tablet) {
      font-size: 44px;
    }

    @include bp(desktop-large) {
      font-size: 48px;
    }

    @include bp(desktop-wide) {
      font-size: 52px;
    }
  }

  p {
    font-size: 20px;
    margin-bottom: 40px;
  }

  @include bp(tablet) {
    padding-top: 72px;
    padding-bottom: 88px;
  }

  @include bp(desktop-large) {
    padding-top: 80px;
    padding-bottom: 96px;
  }

  @include bp(desktop-wide) {
    padding-top: 88px;
    padding-bottom: 104px;
  }
}

.videos__more {
  color: $color__green--light;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  @include bp(0 tablet) {
    display: block;
  }

  @include bp(tablet) {
    margin-left: 16px;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.videos__item {
  h4 {
    @include bp(tablet desktop-wide) {
      font-size: 14px;
    }
    font-size: 16px;
    line-height: 1.25;
  }

  p {
    font-size: 14px !important;
    line-height: 18px !important;
    margin-bottom: 0;
  }
}

.videos__row {
  @include bp(tablet) {
    display: flex;
    flex-wrap: wrap;
  }

  @include bp(desktop-large) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    row-gap: 0;
    // display: flex;
  }
}

.videos__main {
  margin-bottom: 32px;

  p {
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 0;
  }

  @include bp(tablet desktop-large) {
    width: calc(50% - 16px);
    margin-right: 16px;
    margin-bottom: 0;
  }

  @include bp(tablet-wide desktop-large) {
    width: calc(50% - 40px);
    margin-right: 40px;
  }

  @include bp(desktop-large) {
    margin-bottom: 0;
    // width: 34.5%;
  }
}

.videos__list {
  margin-bottom: 36px;

  @include bp(tablet) {
    margin-bottom: 0;
    // max-width: 445px;
  }

  @include bp(tablet desktop-large) {
    width: calc(50% - 16px);
    margin-left: 16px;
  }

  @include bp(tablet-wide desktop-large) {
    width: calc(50% - 40px);
    margin-left: 40px;
  }

  @include bp(desktop-large) {
    margin-right: 0;
  }

  @include bp(desktop-wide) {
    flex-shrink: 0;
  }

  &_last {
    @include bp(tablet desktop-large) {
      width: 100%;
      // max-width: 100%;
      margin-top: 56px;
      margin-left: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 32px;
    }

    @include bp(tablet-wide desktop-large) {
      column-gap: 80px;
    }
  }

  // &:last-child {
  //   @include bp(tablet) {
  //     margin-right: 0;
  //     max-width: 448px;
  //     flex-shrink: 1;
  //   }
  // }
}

.videos__head {
  margin-bottom: 24px;

  @include bp(tablet) {
    display: flex;
    align-items: baseline;
    margin-bottom: 32px;
  }

  @include bp(desktop-large) {
    margin-bottom: 40px;
  }

  @include bp(desktop-wide) {
    margin-bottom: 48px;
  }
  a {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: $color__green--light;

    @include bp(tablet) {
      font-size: 18px;
      margin-left: 16px;
    }

    @include bp(desktop-large) {
      display: inline;
      font-size: 20px;
    }
  }
}
