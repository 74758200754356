@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.InsightItem {
  &:not(.InsightItem__main):not(.InsightItem_slideritem):not(
      .InsightItem__full
    ) {
    margin: 0 0 24px 0;

    @include bp(tablet) {
      margin: 0 0 16px 0;
    }

    @include bp(mobile tablet) {
      margin: 0 0 32px 0;
    }

    @include bp(desktop-large) {
      margin: 0 0 24px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
    a {
      padding: 0;
      display: flex;
    }
  }

  a {
    color: $color__black--lighter;
  }

  &:hover {
    .InsightItem__icon {
      transform: scale(1.1);
    }
  }
}

.InsightItem__imgwrap {
  max-width: 144px;
  width: 100%;
  height: 80px;
  background-color: #252c2e;
  margin: 0 24px 0 0;
  position: relative;
  flex: 0 0 168px;
  font-size: 0;
  line-height: 0;

  &.main {
    max-width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // @include bp(mobile tablet) {
  //   margin: 0 40px 0 0;
  //   min-width: 264px;
  //   height: 174px;
  // }

  &:hover {
    opacity: 0.9;
  }

  .InsightItem__icon {
    width: 32px;
    height: 32px;
    position: absolute;
    // right: 8px;
    left: 8px;
    bottom: 8px;
    pointer-events: none;
    transition: all 0.4s;
  }

  @include bp(tablet) {
    // margin: 0 40px 0 0;
  }
}

.InsightItem__main {
  width: 100%;
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: #fff;

  position: relative;

  &:hover {
    opacity: 0.9;
  }

  .InsightItem__icon {
    width: 56px;
    height: 56px;
    position: absolute;
    bottom: 16px;
    left: 16px;
    pointer-events: none;
    transition: all 0.4s;
    z-index: 1;

    position: relative;
    margin-right: 24px;
    left: 0;
    bottom: 0;
    flex-shrink: 0;
    grid-row: 1/3;
  }

  .InsightItem__img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    flex-grow: 1;

    @include bp(tablet desktop-large) {
      max-height: 300px;
    }

    @include bp(desktop-large) {
      height: 100%;
    }
  }

  .InsightItem__contentwrap {
    background-color: transparent;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 32px 16px 35px 24px;

    @include bp(desktop-large) {
      height: 136px;
    }
  }

  .InsightItem__text {
    font-weight: 500;

    p {
      margin-top: 0;
    }
  }

  .InsightItem__imgwrap {
    @include bp(desktop-large) {
      height: 256px;
    }
  }

  a {
    @include bp(desktop-large) {
      height: 100%;
    }
  }

  .InsightItem__date {
    grid-column: 2;
    margin-top: 32px;
    margin-bottom: 8px;
  }

  .InsightItem__title {
    grid-column: 2;
  }
}

.InsightItem__full {
  position: relative;

  a {
    display: block;
    position: relative;
    width: 100%;
    cursor: pointer;
  }

  .InsightItem__imgwrap {
    width: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(4, 51, 72, 0) 32%, #043348 100%);
    }
  }

  .InsightItem__img {
    width: 100% !important;
    height: 176px !important;

    @include bp(mobile) {
      height: 520px !important;
    }

    @include bp(desktop-large) {
      height: 314px !important;
    }

    @include bp(desktop-wide) {
      height: 386px !important;
    }
  }

  .InsightItem__imgwrap {
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .InsightItem__contentwrap {
    position: absolute;
    left: 12px;
    bottom: 12px;
    flex-direction: row;
    align-items: center;

    @include bp(mobile) {
      left: 24px;
      bottom: 24px;
    }

    @include bp(desktop-large) {
      bottom: 26px;
    }
  }

  .InsightItem__icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    transition: all 0.4s;

    @include bp(mobile) {
      width: 80px;
      height: 80px;
      margin-right: 32px;
    }
  }

  .InsightItem__desc {
  }

  .InsightItem__date {
    color: #ffffff;
    opacity: 0.8;
    font-weight: 300;
  }

  .InsightItem__title {
    color: #ffffff;

    @include bp(tablet) {
      font-size: 15px;
      line-height: 1.3;
    }
  }

  @include bp(desktop-large) {
    margin-left: 56px;
  }
}

.InsightItem__contentwrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InsightItem__title {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin: 0 0 5px 0;

  @include bp(tablet) {
    font-size: 15px;
    line-height: 24px;
  }
}

p.InsightItem__text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0;
  color: $color__black--lighter;

  @include bp(mobile) {
    font-size: 32px;
    line-height: 40px;
  }

  @include bp(tablet) {
    font-size: 20px;
    line-height: 24px;
  }
}

.InsightItem__date {
  display: block;
  font-size: 12px;
  opacity: 0.2;
  font-weight: 400;
  margin-bottom: 4px;

  @include bp(tablet) {
    font-size: 14px;
  }

  .InsightItem__main & {
    margin-top: 32px;
    margin-bottom: 8px;
  }
}

.InsightItem_slideritem {
  .InsightItem__imgwrap {
    width: 100%;
    height: 172px;
    margin: 0;
    margin-bottom: 20px;

    .InsightItem__icon {
      width: 44px;
      height: 44px;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      @include bp(mobile) {
        width: 88px;
        height: 88px;
      }

      @include bp(tablet) {
        width: 48px;
        height: 48px;
        left: 16px;
        right: auto;
        top: auto;
        bottom: 16px;
      }

      @include bp(desktop-wide) {
        width: 56px;
        height: 56px;
      }
    }

    @include bp(mobile) {
      height: 344px;
      margin-bottom: 40px;
    }

    @include bp(tablet) {
      height: 168px;
      margin-bottom: 20px;
    }

    @include bp(desktop-large) {
      height: 152px;
      margin-bottom: 24px;
    }

    @include bp(desktop-wide) {
      height: 252px;
      margin-bottom: 32px;
    }
  }

  .InsightItem__contentwrap {
    text-align: center;
    max-width: 232px;
    margin-left: auto;
    margin-right: auto;

    @include bp(mobile) {
      max-width: 496px;
    }

    @include bp(tablet) {
      text-align: left;
    }
  }

  .InsightItem__title,
  .InsightItem__text {
    font-size: 14px;
    line-height: 1.28;

    @include bp(mobile) {
      font-size: 28px;
      line-height: 1.14;
    }

    @include bp(tablet) {
      font-size: 16px;
      line-height: 1.2;
    }

    // @include bp(desktop-wide) {
    //   font-size: 20px;
    // }
  }
}

.InsightItem.InsightItem_onlyicon {
  .InsightItem__imgwrap {
    width: 56px;
    height: 56px;
    background: none;
    margin: 0 24px 0 0;
    position: relative;
    flex: 0 0 56px;
  }

  .InsightItem__icon {
    width: 56px;
    height: 56px;
    position: relative;
    left: auto;
    bottom: auto;
    pointer-events: all;
    transition: all 0.4s;
  }
}

.InsightItem_posts {
  display: block !important;

  .InsightItem__imgwrap {
    max-width: 100%;
    height: auto;

    @include bp(tablet) {
      height: 256px;
    }
  }

  .InsightItem__icon {
    left: 20px;
    bottom: 16px;
    width: 44px;
    height: 44px;

    @include bp(tablet) {
      left: 16px;
      width: 56px;
      height: 56px;
    }

    @include bp(desktop-large) {
      bottom: 7px;
    }

    @include bp(desktop-wide) {
      bottom: 16px;
    }
  }

  .InsightItem__title {
    font-size: 20px;
    line-height: 1.2;

    p {
      margin: 0;
    }

    @include bp(desktop-large) {
      font-size: 22px;
    }
  }

  .InsightItem__date {
    margin-bottom: 16px;
  }

  .InsightItem__contentwrap {
    padding: 16px 0 32px;
    background-color: transparent;
    transition: background-color 0.3s;

    @include bp(tablet) {
      padding-top: 20px;
      padding-bottom: 54px;
    }

    @include bp(desktop-large) {
      padding: 24px 0 54px;
    }

    @include bp(desktop-wide) {
      padding: 32px 0 56px;
    }
  }
}

.InsightItem_next {
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h4 {
    font-size: 13px;
    line-height: 1.3;
    margin: 0;
  }

  .InsightItem__contentwrap {
    max-width: 80px;

    @include bp(mobile) {
      max-width: 200px;
    }

    @include bp(desktop-large) {
      max-width: 210px;
    }
  }

  .InsightItem__imgwrap {
    max-width: 120px;
    max-height: 64px;
    margin-right: 24px;
  }

  .InsightItem__nexttitle {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 300;
    opacity: 0.2;
    display: block;
    margin-bottom: 4px;
  }

  .InsightItem__icon {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 2px solid $color__green--light;
    border-right: 2px solid $color__green--light;
    transform: rotate(-45deg);

    @include bp(desktop-large) {
      width: 28px;
      height: 28px;
      margin-left: 16px;
    }

    @include bp(desktop-wide) {
      width: 30px;
      height: 30px;
    }
  }
}
