@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.posts {
  background-color: #f7f8f9;
  color: $color__darkblue;
}

.posts__container {
  padding-top: 0;
  padding-bottom: 0;
}
