@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.platform {
  font-size: 20px;
  line-height: 28px;
  color: $color__black--lighter;
  padding-top: 52px;
  padding-bottom: 52px;

  background-color: #fff;

  @include bp(tablet) {
    padding-bottom: 72px;
  }

  @include bp(desktop-large) {
    padding-top: 96px;
    padding-bottom: 88px;
  }

  // @include bp(desktop-wide) {
  //   padding-top: 168px;
  //   padding-bottom: 96px;
  // }
}

.platform__important {
  font-weight: 300;
  line-height: 1.23;
  font-size: 26px;
  margin-bottom: 18px;

  @include bp(tablet) {
    font-size: 32px;
    margin-bottom: 24px;
  }

  @include bp(desktop) {
    font-size: 36px;
    margin-bottom: 0;
  }

  @include bp(desktop-wide) {
    font-size: 44px;
  }

  strong {
    font-weight: 300;
    display: block;

    @include bp(desktop) {
      max-width: 512px;
    }

    @include bp(desktop-wide) {
      max-width: 688px;
    }
  }
}

.platform__text {
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 0;

    @include bp(tablet) {
      font-size: 18px;
    }

    @include bp(desktop-wide) {
      font-size: 20px;
    }
  }
}

.platform__desc {
  @include bp(tablet) {
    margin-bottom: 32px;
  }

  @include bp(desktop) {
    margin-bottom: 24px;
  }
}

.platform__links {
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    color: $color__green--light;
    font-weight: 600;
    font-size: 16px;

    @include bp(tablet) {
      font-size: 18px;
    }
  }

  li + li {
    margin-top: 18px;

    @include bp(tablet) {
      margin-top: 0;
      margin-left: 24px;
    }
  }

  @include bp(tablet) {
    display: flex;
  }
}
