@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.room {
  background-image: url("/images/rroom-bg-mobile.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 52px;
  padding-bottom: 52px;

  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @include bp(tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: url("/images/rroom-bg-light-new-gradient.jpg");
    background-position: top;
  }
  @include bp(desktop-large) {
    padding-top: 148px;
    padding-bottom: 148px;
    background-position: bottom right;
  }
  @include bp(desktop-wide) {
    padding-top: 96px;
    padding-bottom: 108px;
  }
}

.room__head {
  @include bp(tablet) {
    margin-bottom: 20px;
  }
}

.room__title {
  width: 100%;
  color: $color__darkblue-2;
  font-weight: 300;
  font-size: 36px;
  line-height: 52px;
  margin: 0 0 32px 0;

  a {
    display: none;

    @include bp(tablet) {
      color: $color__green--light;
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
      margin-left: 16px;
    }

    @include bp(desktop-wide) {
      font-size: 20px;
    }
  }

  @include bp(tablet) {
    font-size: 42px;
    margin: 0 0 24px 0;
    text-align: center;
  }

  @include bp(desktop-large) {
    font-size: 48px;
    line-height: 56px;
  }
  @include bp(desktop-wide) {
    font-size: 56px;
    line-height: 56px;
  }
}

.room__container {
  overflow-y: scroll;

  &:not(.room__container_page) {
    max-height: 532px;
  }

  @include bp(0 tablet) {
    padding-top: 0;
  }
  @include bp(tablet) {
    display: flex;
    justify-content: space-between;
    overflow-y: visible;
  }

  &.room__container_page {
    @include bp(tablet) {
      display: flex;
      justify-content: space-between;
      overflow-y: visible;
    }
  }
}

.room__desc {
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;

  br {
    display: none;
    @include bp(tablet) {
      display: inline-block;
    }
  }

  @include bp(tablet) {
    font-size: 18px;
    text-align: center;
  }
}

.room .room__items {
  overflow-x: hidden;
  @include bp(tablet) {
    padding-top: 20px;
  }
  @include bp(tablet) {
    height: auto;
    padding-top: 14px;
    overflow: visible;
  }
}

.room__item {
  @include bp(desktop-large) {
    display: flex;
    justify-content: space-between;
  }
}

.room__name {
  text-align: center;
  position: relative;
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 48px;

  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 14px;
    height: 14px;
    border-bottom: 2px solid $color__green--light;
    border-right: 2px solid $color__green--light;
    transform: rotate(-45deg);
    z-index: 2;

    @include bp(tablet) {
      top: 13px;
      width: 22px;
      height: 22px;
      border-width: 2px;
    }
    @include bp(desktop-large) {
      left: 16px;
    }
  }
  &:hover:before,
  &:focus:before,
  &_active {
    cursor: default;
  }
  &_active:before {
    border-color: #ffffff;
    transform: rotate(45deg);

    @include bp(tablet) {
      transform: rotate(-45deg);
    }
  }
  strong {
    color: $color__darkblue-2;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    z-index: 2;
    text-align: left;

    @include bp(tablet) {
      font-size: 18px;
    }

    @include bp(desktop-wide) {
      font-size: 20px;
    }
  }

  @include bp(tablet) {
    height: 64px;
    padding-left: 40px;
    margin-bottom: 16px;
  }
  @include bp(desktop-large) {
    width: 352px;
    height: 48px;
    padding-left: 56px;
    padding-left: 66px;
    &:not(.room__name_desktop) {
      display: none;
    }
  }
  @include bp(desktop-wide) {
    width: 411px;
    height: 56px;
    padding-left: 64px;
  }
}

.room__name_active {
  strong {
    color: #ffffff;
    @include bp(tablet) {
      margin-left: 12px;
    }
  }
  &:hover:before {
    @include bp(tablet) {
      border-color: #ffffff;
    }
  }

  @include bp(tablet) {
    &:before {
      border-color: #ffffff;
    }
  }
}

.room__highlighter {
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
  height: 100%;
  z-index: 1;
  background: transparent;
  transition: background 0.3s;

  .room__name_active & {
    background: $color__green--light;
  }

  @include bp(mobile tablet) {
    margin-left: -28px;
    margin-right: -28px;
    width: calc(100% + 56px);
  }

  @include bp(tablet) {
    width: 2000px;
    margin-left: -200px;
    margin-right: 0;
  }
  @include bp(desktop-large) {
    width: 100%;
    padding-right: 30px;
    margin-left: 0;
    background: transparent;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 30px);
      height: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 27.5px 0 27.5px 30px;
      border-color: transparent;
    }
    .room__name:hover &,
    .room__name:focus &,
    .room__name_active & {
      background: transparent;
    }
    // .room__name:hover &:before,
    // .room__name:focus &:before,
    .room__name_active &:before {
      background: $color__green--light;
    }
    // .room__name:hover &:after,
    // .room__name:focus &:after,
    .room__name_active &:after {
      border-color: transparent transparent transparent $color__green--light;
    }
  }
  @include bp(desktop-wide) {
    &:after {
      border-width: 32px 0 32px 30px;
    }
  }
}

.room .room__points {
  padding-top: 32px;
  display: none;

  li + li {
    margin-top: 32px;

    @include bp(tablet) {
      margin-top: 32px;
    }
  }

  &_active {
    display: block;
  }

  strong {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: $color__green--light;
    display: block;
    margin-bottom: 10px;

    @include bp(tablet) {
      font-size: 18px;
      line-height: 24px;
    }
    @include bp(desktop-wide) {
      font-size: 20px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;

    @include bp(tablet) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @include bp(tablet) {
    padding-top: 32px;
  }
  @include bp(desktop-large) {
    padding-top: 10px;
    width: 100%;
    top: 0;
    right: 0;
  }
}

.room__list {
  @include bp(desktop-large) {
    position: relative;
  }
}

.room__point {
  a {
    display: block;
    &:hover,
    &:focus {
      text-decoration: none;
    }
    p {
      color: $color__darkblue-2;
    }
  }
}

.room_page {
  background: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;

  overflow: hidden;

  strong {
    color: $color__text;
  }

  @include bp(0 tablet) {
    .room__item:hover .room__name strong {
      color: #ffffff;
    }
  }

  .room__point {
    p {
      color: $color__text;
    }
  }

  .room__name {
    &:before {
      @include bp(tablet) {
        left: 0;
      }
    }
    &:hover:before {
      @include bp(tablet) {
        border-color: $color__green--light;
      }
    }
    strong {
      @include bp(tablet) {
        font-size: 18px;
        text-align: left;
        margin-left: 52px;
      }
      @include bp(desktop-wide) {
        font-size: 20px;
      }
    }
    @include bp(tablet) {
      width: $highlights-width-desktop;
      height: 55px;

      padding-left: 0;
    }
    @include bp(desktop-wide) {
      width: $highlights-width-wide;
    }
  }

  .room__name:not(.room__name_desktop) {
    @include bp(tablet) {
      display: none;
    }
  }

  .room__name_active {
    strong {
      color: #ffffff;
      @include bp(tablet) {
        margin-left: 66px;
      }
    }
    &:hover:before {
      @include bp(tablet) {
        border-color: #ffffff;
      }
    }

    @include bp(tablet) {
      &:before {
        border-color: #ffffff;
        left: 14px;
      }
    }
  }

  .room__points {
    @include bp(tablet) {
      padding-top: 10px;
      height: auto;
    }
  }

  .room__highlighter {
    @include bp(tablet) {
      width: 100%;
      padding-right: $highlights-triangle-width;
      margin-left: 0;
      background: transparent;
      &:before {
        content: "";
        position: absolute;
        right: $highlights-triangle-width;
        left: auto;
        // left: 0;
        top: 0;
        width: 10000px;
        // width: calc(100% - 30px);
        height: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 27.5px 0 27.5px $highlights-triangle-width;
        border-color: transparent;
      }
    }
  }

  .room__name:hover .room__highlighter,
  .room__name:focus .room__highlighter,
  .room__name_active .room__highlighter {
    @include bp(tablet) {
      background: transparent;
    }
  }

  .room__name_active .room__highlighter:before {
    @include bp(tablet) {
      background: $color__green--light;
    }
  }

  .room__name_active .room__highlighter:after {
    @include bp(tablet) {
      border-color: transparent transparent transparent $color__green--light;
    }
  }

  .room__list {
    @include bp(tablet) {
      position: relative;
      padding-bottom: 108px;
    }
  }

  .room__items {
    position: relative;
    padding-bottom: 88px;

    @include bp(tablet) {
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
    }
    @include bp(desktop-large) {
      padding-top: 0;
    }
  }

  .room__container {
    @include bp(tablet) {
      max-height: 2000px;
      padding-top: 0;
      padding-top: 32px;
      padding-bottom: 22px;
    }
  }

  .room__pointsContainer {
    @include bp(tablet desktop-large) {
      width: calc(
        100% - $points-padding-left-desktop + $highlights-triangle-width
      );
      margin-left: auto;
    }
    @include bp(desktop-large) {
      overflow: visible;
      width: calc(
        100% - $points-padding-left-desktop + $highlights-triangle-width
      );
      margin-left: auto;

      max-height: 100%;
    }
    @include bp(desktop-wide) {
      width: calc(
        100% - $points-padding-left-wide + $highlights-triangle-width
      );
      margin-left: auto;
    }
  }
}

.room__pointsContainer {
  @include bp(desktop-large) {
    width: 560px;
    max-height: 440px;
    // overflow-y: scroll;
  }
  @include bp(desktop-wide) {
    width: 624px;
  }
}

.room__showmore {
  position: absolute;
  bottom: -40px;
  left: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s;

  &_active {
    opacity: 1;
  }

  img {
    margin-right: 17px;
    animation: shaking 1.7s infinite;
  }

  span {
    color: #818c99;
    opacity: 0.5;
  }
  @include bp(desktop-wide) {
    right: 360px;
    bottom: 10px;
  }
}

@keyframes shaking {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-3px);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.room__list_mobilemenu {
  @include bp(0 tablet) {
    .room__name {
      display: none;
    }
  }
}

.room__names {
  display: none;
  @include bp(desktop-large) {
    display: block;
  }
  &_page {
    position: relative;
    @include bp(tablet) {
      display: block;
    }
  }
  &_page:before {
    @include bp(tablet) {
      content: "";
      position: absolute;
      right: calc(
        100% - $highlights-width-desktop + $highlights-triangle-width
      );
      top: -32px;
      width: 10000px;
      height: calc(100% + 64px);
      background-color: $color__gray--light;
    }
    @include bp(desktop-wide) {
      right: calc(100% - $highlights-width-wide + $highlights-triangle-width);
    }
  }
}

.room_mainpage {
  color: $color__darkblue-2;
  background-image: url("/images/rroom-bg-light.jpg");

  h2,
  p {
    color: $color__darkblue-2;
  }
  .room__head {
    @include bp(desktop-wide) {
      margin-bottom: 0;
    }
  }

  .room__items,
  .room__container {
    @include bp(desktop-wide) {
      padding-top: 0;
    }
  }

  .room__name_active strong {
    color: $color__darkblue-2;
  }

  @include bp(tablet) {
    padding-bottom: 88px;
  }

  @include bp(desktop-large) {
    padding-bottom: 70px;
  }

  @include bp(desktop-wide) {
    padding-bottom: 78px;
  }
}

.room__names_mainpage {
  display: block;

  .room__highlighter {
    display: none;
  }

  .room__name_active {
    &:before {
      left: 0;
      transition: all 0.3s;
      border-color: $color__green--light;
    }
    &:hover:before {
      border-color: #ffffff;
      left: 8px;
    }
  }

  .room__name {
    cursor: pointer;

    &:before {
      transition: all 0.3s;

      @include bp(tablet) {
        left: 0;
      }
    }

    &:hover:before {
      border-color: $color__green--light;
      @include bp(desktop-large) {
        left: 8px;
      }

      @include bp(desktop-wide) {
        left: 12px;
      }
    }

    strong {
      transition: all 0.3s;
      @include bp(tablet) {
        margin-left: 0;
      }
    }

    &:hover strong {
      color: $color__green--light;
      @include bp(desktop-large) {
        margin-left: 8px;
      }
      @include bp(desktop-wide) {
        margin-left: 12px;
      }
    }

    // @include bp(tablet) {
    //   width: 50%;
    // }

    @include bp(tablet) {
      width: 33.3%;
      margin-bottom: 10px;
      padding-left: 52px;
    }
  }

  @include bp(tablet) {
    display: flex;
    flex-wrap: wrap;
  }
}

.room_event {
  .room__desc {
    color: $color__darkblue-2;
  }

  .room__highlighter:after {
    @include bp(tablet desktop-large) {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;

      border-color: transparent;

      border-width: 24.5px 0 24.5px 30px;
    }

    @include bp(desktop-wide) {
      border-width: 28px 0 28px 30px;
    }
  }

  .room__pointsContainer {
    @include bp(tablet) {
      width: 520px;
      max-height: 440px;
      overflow-y: scroll;
    }

    @include bp(desktop-large) {
      width: 672px;
    }

    @include bp(desktop-wide) {
      width: 1024px;
    }

    @-moz-document url-prefix() {
      & {
        scrollbar-width: thin; /* Makes the scrollbar narrower */
        scrollbar-color: $color__green--light $color__gray--light; /* Thumb color and track color */
      }
    }

    /* WebKit-based browsers */
    &::-webkit-scrollbar {
      width: 2px; /* Width for vertical scrollbars */
      height: 2px; /* Height for horizontal scrollbars */
    }

    &::-webkit-scrollbar-track {
      background: $color__gray--light; /* Track background */
      border-radius: 0; /* Optional: Round corners */
    }

    &::-webkit-scrollbar-thumb {
      background: $color__green--light; /* Thumb (handle) color */
      border-radius: 0; /* Optional: Round corners */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $color__green--light; /* Hover effect on the handle */
    }
  }

  .room__names {
    @include bp(tablet) {
      display: block;
    }
  }

  .room__name:not(.room__name_desktop) {
    @include bp(tablet) {
      display: none;
    }
  }

  .room__name {
    @include bp(tablet desktop-large) {
      height: 48px;
      width: 352px;
      &_active .room__highlighter:before {
        background: #25b778;
      }

      &_active .room__highlighter:after {
        border-color: transparent transparent transparent #25b778;
      }
    }
  }

  .room__highlighter {
    @include bp(tablet) {
      width: 100%;
      margin-left: 0;
      background: transparent;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 30px);
      height: 100%;
    }

    &:after {
      @include bp(desktop-large desktop-wide) {
        border-width: 24px 0 24px 30px;
      }
    }
  }

  .room__points {
    @include bp(tablet) {
      padding-top: 0;
    }
  }

  @include bp(desktop-large) {
    padding-top: 20px;
  }
}
