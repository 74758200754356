.pagination {
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;

  @include bp(mobile tablet) {
    bottom: 96px;
  }

  a {
    cursor: pointer;
  }
}

.pagination__arrow {
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid $color__green--light;
  border-left: 2px solid $color__green--light;

  @include bp(mobile tablet) {
    width: 20px;
    height: 20px;
  }

  &_prev {
    margin-right: 34px;
    transform: rotate(45deg);
    @include bp(mobile tablet) {
      margin-right: 68px;
    }
  }

  &_next {
    margin-left: 34px;
    transform: rotate(-135deg);
    @include bp(mobile tablet) {
      margin-left: 68px;
    }
  }

  &.disabled {
    border-color: rgba($color__darkblue, 0.1);
  }
}

.pagination__page {
  position: relative;

  & + & {
    margin-left: 32px;

    @include bp(mobile tablet) {
      margin-left: 64px;
    }
  }

  a {
    color: $color__darkblue-light;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: block;
    z-index: 2;
    position: relative;
    @include bp(mobile tablet) {
      font-size: 32px;
    }
  }

  &.active {
    a {
      color: #ffffff;
    }
    &:before {
      position: absolute;
      content: "";
      top: -4px;
      left: -11px;
      width: 32px;
      height: 32px;
      background-color: $color__green--light;
      transform: rotate(45deg);
      z-index: 1;
      @include bp(mobile tablet) {
        top: -12px;
        left: -18px;
        width: 56px;
        height: 56px;
      }
    }
  }
}
