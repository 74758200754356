@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.place {
  padding: 64px 0 40px;
  background-color: #ffffff;

  @include bp(desktop) {
    min-height: 550px;
  }

  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 24px;
    margin-top: 0;

    @include bp(tablet) {
      font-size: 44px;
      margin-bottom: 24px;
    }

    @include bp(desktop) {
      font-size: 48px;
      margin-bottom: 32px;
    }

    @include bp(desktop-wide) {
      font-size: 52px;
      margin-bottom: 40px;
    }
  }

  p {
    color: $color__darkblue-2;
    line-height: 1.5;

    @include bp(tablet) {
      font-size: 18px;
      max-width: 432px;
    }

    @include bp(desktop) {
      max-width: 512px;
    }

    @include bp(desktop-wide) {
      font-size: 20px;
      max-width: 688px;
    }
  }

  @include bp(tablet) {
    padding: 60px 0;
  }

  @include bp(desktop) {
    padding: 80px 0;
  }

  @include bp(desktop-wide) {
    padding: 96px 0;
  }
}

.place__images {
  position: relative;

  // width: 376px;

  img {
    width: 236px;
    height: 144px;
    object-fit: cover;

    @include bp(tablet) {
      width: 328px;
      height: 208px;
    }

    @include bp(desktop) {
      width: 440px;
      height: 280px;
    }

    @include bp(desktop-wide) {
      width: 496px;
      height: 280px;
    }
  }

  img:nth-of-type(1) {
    position: relative;
    z-index: 1;
  }

  img:nth-of-type(2) {
    position: absolute;

    top: 48px;
    left: 84px;

    @include bp(tablet) {
      top: 70px;
      left: 130px;
    }

    @include bp(desktop) {
      top: 141px;
      left: 192px;
    }

    @include bp(desktop-wide) {
      top: 120px;
      left: 256px;
    }
  }

  strong {
    @include textIcon();
    @include textIconLocation();
    color: $color__darkblue-2;
    margin-top: 68px;

    @include bp(tablet) {
      margin-top: 123px;
    }

    @include bp(desktop) {
      position: absolute;
      top: 261px;
      left: 0;
      margin-top: 0;
      width: 176px;
      align-items: baseline;
    }

    @include bp(desktop-wide) {
      top: 336px;
      width: 225px;
    }
  }
}

.place__media {
  display: flex;
  justify-content: center;

  @include bp(tablet) {
    justify-content: flex-start;
  }

  @include bp(desktop) {
    // justify-content: flex-end;
  }
}