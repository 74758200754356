@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.listitem {
  box-shadow: 4px 4px 26px 0px #00000012;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.listitem__bottom {
  padding: 24px 24px 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @include bp(desktop-wide) {
    padding: 32px 24px;
  }
}

.listitem__bottom_content {
  flex-grow: 1;
}

.listitem__title {
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 12px;
  color: initial;

  @include bp(desktop-large) {
    font-size: 22px;
    line-height: 1.27;
    margin-bottom: 16px;
  }

  @include bp(desktop-wide) {
    font-size: 24px;
  }
}

.listitem__date {
  font-size: 16px;
  letter-spacing: 3%;
  color: rgba($color__darkblue, 0.4);
  margin-bottom: 8px;

  @include bp(desktop-wide) {
    margin-bottom: 16px;
  }
}

.listitem__location {
  @include textIcon();
  @include textIconLocation();
  margin-bottom: 16px;
  font-size: 10px;
  color: rgba($color__darkblue-2, 0.4);

  &.online {
    @include textIconPlay();
  }

  a {
    color: rgba($color__darkblue-2, 0.4);
  }

  &:before {
    margin-right: 8px;
  }

  @include bp(tablet) {
    font-size: 10px;
  }

  @include bp(desktop-large) {
    font-size: 10px;
  }
}

.listitem__btns {
  display: flex;
  align-items: center;
  min-height: 48px;
}

.listitem__link {
  color: $color__green--light;
  font-weight: 600;
  font-size: 16px;
}

.listitem__btn {
  margin-right: 24px;

  &_signup {
    color: #ffffff;
  }
}

.listitem__shortdate {
  font-weight: 500;
  font-size: 40px;
  line-height: 1;
  color: #ffffff;
  position: absolute;
  top: 16px;
  left: 24px;
  width: 72px;
  height: 72px;
  background-color: $color__darkblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp(desktop-wide) {
    font-size: 44px;
    width: 80px;
    height: 80px;
    top: 24px;
  }

  span {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;

    @include bp(desktop-wide) {
      font-size: 20px;
    }
  }
}

.listitem__labels {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 24px;
}

.listitem__label {
  color: #ffffff;
  background-color: $color__darkblue;
  font-size: 12px;
  text-transform: uppercase;
  padding: 4px 5px;

  &.online {
    background-color: $color__blue;
    margin-left: 8px;
  }
}

.listitem__img {
  position: relative;
  display: block;

  img {
    height: 180px;
    width: 100%;

    object-fit: cover;

    @include bp(tablet) {
      height: 248px;
    }

    @include bp(desktop-large) {
      height: 192px;
    }

    @include bp(desktop-wide) {
      height: 256px;
    }
  }
}
